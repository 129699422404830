/* You can add global styles to this file, and also import other style files */
@import "../../../node_modules/@fortawesome/fontawesome-pro/css/all.css";


@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
    @tailwind base;
}

@layer tailwind-utilities {
    @tailwind utilities;
    @tailwind variants;
    @tailwind components;
}

:root {
  --p-datatable-header-padding: 1.25rem;
  --p-datatable-header-background: var(--p-surface-50);
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: 15px;
}



.prod-border { border-bottom: 3px solid #85c73b; }
.wmrt-logo { background: url(images/walmart-sm.png) no-repeat; height: 53px; width: 200px; }
.trgt-logo { background: url(images/Target-sm.png) no-repeat; height: 53px; width: 200px; }
.hyve-logo { margin-top: 15px; background: url(images/HyVee.png) no-repeat;height: 53px; width: 200px;}
.dlln-logo { background: url(images/Dillons.png) no-repeat;height: 53px; width: 200px; }
.segi-logo { background: url(images/segi.png) no-repeat;height: 75px; width: 294px; }
.hbkd-logo { background: url(images/hbh-sm.png) no-repeat;height: 53px; width: 200px; }
.asm-logo-sm { background: url(images/asm-logo-sm.png) no-repeat;height: 35px; width: 100px; }
.asm-logo { background: url(images/asm-logo.png) no-repeat; height: 56px; width: 207px; }
.asm-logo-test { background: url(images/asm-logo-test.png) no-repeat; height: 85px; width: 207px; }
.login-page { background: url(images/bg.jpg) no-repeat top center #2d494d; }
.ack-zones {
  background: url(images/TrailerZonesDiagram.png) no-repeat;
  height: 350px;
  @apply bg-contain bg-center;
}

.ack-zones-es {
    background: url(images/TrailerZonesDiagram_es.png) no-repeat;
    height: 350px;
    @apply bg-contain bg-center;
}

.app-loading div.preloader { text-align: center; font-size: 26px; color: #696969;}
.with-top-navbar { padding-top: 50px; }
.panel-heading { height: 40px; line-height: 35px; }
.panel-heading .panel-tabs > li > a { line-height: 19px; }

.list-group-item.selected
{
    background-color: #ebebeb !important;
    border: solid 1px #e1e1e1 !important;
}

.panel-tile { position: relative; overflow: hidden; }
.bs-component { position: relative; }
.icon-bg {
  position: absolute;
  opacity: 0.5;
  right: -15px;
  top: 0;
  line-height: 110px;
  font-size: 100px;
}


//TODO: Add separate validation style sheet
.stm-required:after {
  content: " *";
  color: #f05050;
}

.stm-errors-list {
  display: block;
  margin: 0;
  padding: 0;
}

.stm-errors-list.stm-hide-errors {
  display: none;
}

.stm-errors-list li {
  font-size: 12px;
  list-style: none;
  color: #f05050;
}

//Custom STM Styles
.stm-detail-header {
  background-color: #f9f9fa;
  border-top: solid 1px #e7e9eb;
  border-bottom: solid 1px #e7e9eb;
}

.errorMessage {
  font-size: 12px;
  list-style: none;
  border: #f05050 1px solid;
  background-color: #ffcccc;
  color: red;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
}

//Customer Error Page
div.error-page .error-title {
  font-size: 140px;
  font-weight: 800;
  color: #3498db;
  text-align: center;
  padding-top: 60px;
  margin-bottom: 20px;
  line-height: 120px;
}
div.error-page .error-subtitle {
  font-weight: 400;
  text-align: center;
  font-size: 40px;
  color: #aaa;
  margin-bottom: 80px;
}

table.table > thead > tr > th,
table.table > tbody > tr > td {
  @apply p-4 border;
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ellipsis div {
  position: absolute;
  top: 16px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #808080;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.screen-cover {
  background-color: black;
  opacity: .5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}


.p-inputtext {
  width: 100%;
}

.p-datepicker-dropdown {
  @apply py-1 px-6;
}


.p-splitbutton>.p-button {
  @apply py-3 px-4
}

.p-inputgroupaddon {
  @apply py-1 px-4;
}

.p-datatable-gridlines .p-datatable-header {
  @apply border-gray-100
}

.btn {
  @apply px-4 py-4 text-base leading-none rounded cursor-pointer;
}

.btn-light {
  @apply inline-block align-middle text-center select-none border font-normal whitespace-nowrap rounded py-1.5 px-2.5 leading-normal no-underline mr-1 hover:bg-gray-50;
}

.p-sortable-column-icon {
  vertical-align: middle !important;
  margin-left: 0.5rem !important;
}